import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-multi-carousel';

//styles
import * as styles from '../styles/components/Index/hosts.module.scss';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Reps = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulRepresentatives {
          edges {
            node {
              name
              slug
              description {
                description
              }
              priority
              image {
                gatsbyImageData(
                  quality: 100
                  width: 400
                  height: 600
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    `
  );

  const reps = data.allContentfulRepresentatives.edges;

  return (
    <Container className='my-5 py-5'>
      <h1 className={styles.titleMain}>Meet Our Reps</h1>
      <Carousel
        responsive={responsive}
        showDots={false}
        infinite={true}
        autoPlaySpeed={500}
        keyBoardControl={true}
        // dotListClass={styles.dots}
        // itemClass="carousel-item-padding-40-px"
      >
        {reps
          .sort((a, b) => a.node.priority - b.node.priority)
          .map(({ node }, idx) => {
            let image = null;
            if (node.image) {
              image = getImage(node.image);
            }
            return (
              <Card className={styles.cardMain} key={idx}>
                <GatsbyImage
                  image={image}
                  alt={node.name}
                  className={styles.image}
                />

                <Card.Body className='text-center'>
                  <Card.Title>
                    <h5 className={styles.title}>{node.name}</h5>
                  </Card.Title>
                  <Card.Text>
                    <p className={`${styles.subtitle}`}>{node.position}</p>
                  </Card.Text>
                </Card.Body>
                <div className={styles.details}>
                  <h6 className={styles.detailsTitle}>{node.name}</h6>
                  <p className={styles.detailsSubTitle}>{node.position}</p>
                  <p className={styles.detailsSummary}>
                    {node.description?.description}
                  </p>
                </div>
              </Card>
            );
          })}
      </Carousel>
    </Container>
  );
};

export default Reps;
