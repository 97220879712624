import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

//styles
import * as styles from '../styles/components/news.module.scss';
import { Col, Container, Row, Card, Button } from 'react-bootstrap';

const News = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulNewsletters {
          edges {
            node {
              title
              document {
                file {
                  url
                }
              }
            }
          }
        }
      }
    `
  );

  const newsFiles = data.allContentfulNewsletters.edges;

  return (
    <>
      <Container>
        <Row className='mt-4'>
          {newsFiles.map((file, i) => (
            <Col key={i} md={6}>
              <Card className='shadow bg-white rounded my-3'>
                <Card.Header>
                  <h4 className={`text-center fw-bold ${styles.title}`}>
                    {file.node.title}
                  </h4>
                </Card.Header>
                <Card.Body className={styles.card}>
                  <iframe
                    src={`${file.node.document?.file?.url}#toolbar=0&navpanes=0&scrollbar=0`}
                    width='100%'
                    height='100%'
                  />
                </Card.Body>

                <a
                  style={{ color: 'white', fontWeight: 'bold' }}
                  href={`${file.node.document?.file?.url}`}
                  target='_blank'
                >
                  <Card.Footer className={styles.downloadBtn}>
                    Download
                  </Card.Footer>
                </a>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default News;
