import React from 'react';
import Layout from '../components/layout';
import Intro from '../components/Index/intro';
import Hosts from '../components/hosts-about';
import Reps from '../components/reps-about';
import News from '../components/news';
import Title from '../components/title';
import SEO from '../components/seo';

const About = () => {
  return (
    <>
      <Layout>
        <Title picture title='About Us' />
        <SEO title='About' />
        <Intro noButton={true} />
        <Hosts />
        <Reps />
      </Layout>
    </>
  );
};

export default About;
